import API from "@/api";

export default {
  getData: params => {
    return API.requestGet({
      url: "index/getDatas",
      auth: true
    });
  },
  getEchartData: params => {
    return API.requestPost({
      url: "index/getLineChart",
      data: params,
      auth: true
    });
  }
};
